<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Incapacidades" :exportInfo="currItems" :dates="[forms.begin, forms.end]">
      <template v-slot:bottom-header>
        <v-row class="mx-4">
          <v-col cols="12" md="3">
            <Date-Picker v-model="forms.begin" label="Fecha inicio" offYear offLimit />
          </v-col>
          <v-col cols="12" md="3">
            <Date-Picker v-model="forms.end" label="Fecha fin" offYear offLimit />
          </v-col>
          <v-col cols="12" md="1">
            <Basic-Btn text="Buscar" color="success" icon="mdi-magnify" @click="refresh()" :loading="loading" :disabled="!(forms.begin && forms.end)" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.nombre`]="{ item }">
          {{ item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <Icon-Btn @onClick="view(item)" label="Editar" icon="mdi-pencil" :disabled="loading" top />
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.update" title="Actualizar incapacidad">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row class="mt-3">
          <v-col cols="12">
            <v-select v-model="forms.update.nuevo_evento" :items="subtypes" label="Evento" prepend-icon="mdi-calendar-week-begin" dense outlined></v-select>
          </v-col>
          <v-col cols="6">
            <Date-Picker v-model="forms.update.nueva_fecha_inicio" label="Fecha inicio" offLimit />
          </v-col>
          <v-col cols="6">
            <Date-Picker v-model="forms.update.nueva_fecha_fin" label="Fecha fin" offLimit />
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from '@/utils/services'
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DatePicker, IconBtn } from '@/components'

export default {
  name: 'inability',
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
    IconBtn,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: 'error',
      msg: '',
    },
    search: '',
    headers: [
      { text: 'Numero de empleado', align: 'left', sortable: true, value: 'no_empleado' },
      { text: 'Nombre', align: 'left', sortable: true, value: 'nombre' },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: 'Evento', align: 'left', sortable: true, value: 'evento' },
      { text: 'Departamento', align: 'left', sortable: true, value: 'departamento' },
      { text: 'Fecha inicio', align: 'left', sortable: false, value: 'fecha_inicio' },
      { text: 'Fecha fin', align: 'left', sortable: false, value: 'fecha_fin' },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    users: [],
    subtypes: [
      'INCAPACIDAD POR ENFERMEDAD GENERAL',
      'INCAPACIDAD POR RIESGO DE TRABAJO',
      'INCAPACIDAD POR MATERNIDAD',
      'INCAPACIDAD TEMPORAL',
    ],
    currItems: [],
    itemsPerPage: 20,
    forms: {
      begin: '',
      end: '',
      update: {
        idusuario: null,
        evento: null,
        fecha_inicio: null,
        fecha_fin: null,
        nuevo_evento: null,
        nueva_fecha_inicio: null,
        nueva_fecha_fin: null,
      }
    },
    dialogs: {
      update: false
    }
  }),
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  methods: {
    refresh() {
      this.loading = true
      let request = '/calendar/full?fecha_inicio=' + this.forms.begin + '&fecha_fin=' + this.forms.end +
      '&eventos=INCAPACIDAD POR MATERNIDAD&eventos=INCAPACIDAD POR RIESGO DE TRABAJO&eventos=INCAPACIDAD POR ENFERMEDAD GENERAL&eventos=INCAPACIDAD TEMPORAL'
      this.axios.get(services.routes.event + request)
        .then((response) => {
          let data = response.data.data
          let empleados = []
          this.items = []
          for (let i = 0; i < data.length; i++) {
            let fecha_inicio = (data[i].fecha_inicio) ? data[i].fecha_inicio : ''
            let fecha_fin = (data[i].fecha_fin) ? data[i].fecha_fin : ''
            if (empleados.indexOf(data[i].no_empleado + '_' + data[i].evento + '_' + fecha_inicio + '_' + fecha_fin) < 0) {
              empleados.push(data[i].no_empleado + '_' + data[i].evento + '_' + fecha_inicio + '_' + fecha_fin)
              this.items.push({
                idusuario: data[i].idusuario,
                no_empleado: data[i].no_empleado,
                nombre: data[i].nombre,
                apellido_paterno: data[i].apellido_paterno,
                apellido_materno: data[i].apellido_materno,
                nombre_completo: data[i].nombre + ' ' + data[i].apellido_paterno + ' ' + data[i].apellido_materno,
                evento: data[i].evento,
                departamento: data[i].departamento,
                fecha_inicio: data[i].fecha_inicio,
                fecha_fin: data[i].fecha_fin
              })
            }
          }
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = 'error'
          this.alert.msg = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
          this.alert.active = true
          this.alert.color = 'success'
          this.alert.msg = 'Registros obtenidos correctamente.'
        })
    },
    cancel() {
      this.forms.update = {
        idusuario: null,
        evento: null,
        fecha_inicio: null,
        fecha_fin: null,
        nuevo_evento: null,
        nueva_fecha_inicio: null,
        nueva_fecha_fin: null,
      }
      this.dialogs.update = false
    },
    view(data) {
      this.forms.update = {
        idusuario: data.idusuario,
        evento: data.evento,
        fecha_inicio: data.fecha_inicio,
        fecha_fin: data.fecha_fin,
        nuevo_evento: data.evento,
        nueva_fecha_inicio: data.fecha_inicio,
        nueva_fecha_fin: data.fecha_fin
      }
      this.dialogs.update = true
    },
    update() {
      if (this.forms.update.nuevo_evento && this.forms.update.nueva_fecha_inicio && this.forms.update.nueva_fecha_fin) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.updateInability, { data })
          .then((response) => {
            this.cancel()
            this.refresh()
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      }
      else {
        this.alert.active = true
        this.alert.color = 'error'
        this.alert.msg = 'La información es incorrecta'
      }
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped>
</style>
